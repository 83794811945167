// Init
import firebase from "firebase/app";
import "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBfoV9IrNDIrxUgrvKpyCjConaAwEeLF4s",
  authDomain: "pmstrivepk.firebaseapp.com",
  projectId: "pmstrivepk",
  storageBucket: "pmstrivepk.appspot.com",
  messagingSenderId: "282340931814",
  appId: "1:282340931814:web:ed53491b2fc4dfb1d8e327",
  measurementId: "G-RFZVS82NGS",
};

// Initializing App
firebase.initializeApp(firebaseConfig);

// Initializing Features
const analytics = firebase.analytics();

// Export
export { analytics };

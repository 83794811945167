// Init
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop.jsx";

import App from "./App";

// Construction
import Construction from "./underConstruction";

// Render
ReactDOM.render(
  <BrowserRouter>
    {/*<ScrollToTop />*/}
    {/*<App />*/}
    <Construction />
  </BrowserRouter>,
  document.getElementById("root"),
);

import React, { useEffect, useState } from "react";
import logo from "./assets/logo.png";

function Construction() {
  const [time, setTime] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(interval);
          return 0; // Ensure that the time doesn't go below 0
        }
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (time === 0) {
      setTimeout(() => {
        window.location = "https://yousufkalim.com";
      }, 1000);
    }
  }, [time]);

  return (
    <section className="login-container">
      <div className="login">
        {/* Logo */}
        <div className="login-logo" style={{ marginBottom: "50px" }}>
          <img src={logo} alt="Logo" />
        </div>

        <h1 className="construction">
          PM Strive has been suspended by the developer
        </h1>
        <br />
        <br />
        <p>You'll be redirected in {time} seconds</p>
      </div>
    </section>
  );
}

export default Construction;
